import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';

const DETECTOR = {
  MOBILE: /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/,
  TABLET: /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i,
};
const CSS_DESKTOP = '(min-width: 62.25em)'; //62.25 * 16 =     996px

const TYPES = {
  DESKTOP: 'DESKTOP',
  TABLET: 'TABLET',
  MOBILE: 'MOBILE',
};

const MEDIA_QUERIES = {
  DESKTOP: {
    NORMAL: '(min-width: 995px)',
  },
  MOBILE: {
    SMALL: '(max-width:479px)',
    NORMAL: '(max-width:768px)',
  },
  TABLET: {
    PORTRAIT: '(min-width: 480px) and (max-width:767px)',
    LANDSCAPE: '(min-width: 767px) and (max-width:1026px)',
  },
  OTHER: {
    BIG: '(min-width:1280px)',
  },
};

const navigatorDetect = () => {
  const isClientMounted = ref(false);
  const currentDevice = ref(null);
  let desktopMediaQueryList;

  onMounted(() => {
    desktopMediaQueryList = window.matchMedia(MEDIA_QUERIES.DESKTOP.NORMAL);
    matchHandler(desktopMediaQueryList);
    if ('addEventListener' in desktopMediaQueryList) {
      desktopMediaQueryList.addEventListener('change', matchHandler);
    } else {
      desktopMediaQueryList.addListener(matchHandler);
    }
    isClientMounted.value = true;
  });

  onUnmounted(() => {
    isClientMounted.value = false;
    if (!desktopMediaQueryList) return;

    if ('removeEventListener' in desktopMediaQueryList) {
      desktopMediaQueryList.removeEventListener('change', matchHandler);
    } else {
      desktopMediaQueryList.removeListener(matchHandler);
    }
  });

  const store = useStore();
  const isServer = store.getters['page/isServer'];
  const userAgent = store.getters['page/getUserAgent'];
  const isDesktopAkamai = store.getters['akamai/isDesktopDevice'];
  const isTabletAkamai = store.getters['akamai/isTabletDevice'];
  const isMobileAkamai = store.getters['akamai/isMobileDevice'];

  const matchHandler = ({ matches }) => {
    if (matches) currentDevice.value = TYPES.DESKTOP;
    else currentDevice.value = TYPES.MOBILE;
  };

  const isDesktopDevice = computed(() => {
    const deviceTypeDesktop = getDeviceType() === TYPES.DESKTOP;
    if (!currentDevice.value) return deviceTypeDesktop;
    return currentDevice.value === TYPES.DESKTOP;
  });

  const isTabletDevice = computed(() => {
    const deviceTypeTablet = getDeviceType() === TYPES.TABLET;
    return deviceTypeTablet;
  });

  const isMobileDevice = computed(() => {
    const deviceTypeMobile = getDeviceType() === TYPES.MOBILE;
    if (!currentDevice.value) return deviceTypeMobile;
    return currentDevice.value === TYPES.MOBILE;
  });

  const isDesktopDeviceByHeaderAndResolution = computed(() => {
    return isDesktopAkamai !== undefined && !isClientMounted.value ? isDesktopAkamai : isDesktopDevice;
  });

  const isTabletDeviceByHeaderAndResolution = computed(() => {
    return isTabletAkamai !== undefined && !isClientMounted.value ? isTabletAkamai : isTabletDevice;
  });

  const isMobileDeviceByHeaderAndResolution = computed(() => {
    return isMobileAkamai !== undefined && !isClientMounted.value ? isMobileAkamai : isMobileDevice;
  });

  const isChrome = computed(() => {
    const UA = isServer ? userAgent : window.navigator.userAgent;
    return /Chrome/i.test(UA);
  });

  const isSafari = computed(() => {
    const UA = isServer ? userAgent : window.navigator.userAgent;
    return /Safari/i.test(UA);
  });

  const isIOS = () => {
    const UA = isServer ? userAgent : window.navigator.userAgent;

    return /iPad|iPhone|iPod/.test(UA) && !window.MSStream;
  };
  const isAndroid = () => {
    const UA = isServer ? userAgent : window.navigator.userAgent;

    return /android/i.test(UA);
  };
  const isMacOS = () => {
    const UA = isServer ? userAgent : window.navigator.userAgent;

    return /Macintosh/.test(UA) && !/iPad|iPhone|iPod/.test(UA);
  };
  const getDeviceType = () => {
    const UA = isServer ? userAgent : window.navigator.userAgent;
    if (DETECTOR.MOBILE.test(UA)) return TYPES.MOBILE;
    if (DETECTOR.TABLET.test(UA)) return TYPES.TABLET;
    return TYPES.DESKTOP;
  };
  const hasMediaQuery = (mq) => {
    const screen = false; // We should be accepting the header Accept-CH: Viewport-Width to have Viewport-Width
    return isServer ? screen : window.matchMedia(mq).matches;
  };
  const isMobileScreen = computed(() => {
    return hasMediaQuery(MEDIA_QUERIES.MOBILE.SMALL);
  });
  const isResponsiveModeScreen = () => {
    return hasMediaQuery(MEDIA_QUERIES.MOBILE.NORMAL);
  };
  const isPortraitTabletScreen = () => {
    return hasMediaQuery(MEDIA_QUERIES.TABLET.PORTRAIT);
  };
  const isLandscapeTabletScreen = () => {
    return hasMediaQuery(MEDIA_QUERIES.TABLET.LANDSCAPE);
  };
  const isBigScreenScreen = () => {
    return hasMediaQuery(MEDIA_QUERIES.OTHER.BIG);
  };
  const isDesktopScreen = () => {
    return !isResponsiveModeScreen() && !isPortraitTabletScreen() && !isLandscapeTabletScreen() && !isBigScreenScreen();
  };
  const isCssDesktopScreen = () => {
    return hasMediaQuery(CSS_DESKTOP);
  };

  return {
    isClientMounted,
    isDesktopDeviceByHeaderAndResolution,
    isTabletDeviceByHeaderAndResolution,
    isMobileDeviceByHeaderAndResolution,
    isDesktopDevice,
    isTabletDevice,
    isMobileDevice,
    isChrome,
    isSafari,
    isIOS,
    isAndroid,
    isMacOS,
    isCssDesktopScreen,
    isMobileScreen,
    isDesktopScreen,
  };
};
export default navigatorDetect;
